import { ApolloError, useMutation } from '@apollo/client'
import { FormikHelpers } from 'formik'
import type { GraphQLFormattedError } from 'graphql/index'
import { useState } from 'react'
import { resetMutation } from './ResetPage.graphql'
import { ResetFormFields } from './ResetPage.types'

export const useReset = (token: string) => {
  const [isDone, setIsDone] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [reset, { loading }] = useMutation(resetMutation)

  const handleReset = async (
    values: ResetFormFields,
    formikHelpers: FormikHelpers<ResetFormFields>,
  ) => {
    try {
      const { data: isDone } = await reset({
        variables: { password: values.password, token },
      })

      setIsDone(isDone)
    } catch (error) {
      if (
        error instanceof ApolloError &&
        (error.cause?.extensions as GraphQLFormattedError['extensions']) // forced type assertion, as ts can't infer the type properly
          ?.code === 'PASSWORD_REUSE'
      ) {
        formikHelpers.setFieldError('error', error.message)
      } else {
        setIsInvalid(true)
      }
    }
  }

  return {
    handleReset,
    loading,
    isDone,
    isInvalid,
  }
}

/* eslint-disable import/no-default-export */
import { IonContent, IonPage } from '@ionic/react'
import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { passwordValidator } from '@synctech/portal-types'
import * as yup from 'yup'
import { TextInputField } from '../../components/fields/TextInputField'
import { ErrorComponent } from '../../components/ui/ErrorComponent'
import { Button } from '../../components/ui/Button'
import { FlexBox } from '../../components/ui/FlexBox'
import { useReset } from './ResetPage.hooks'
import { ResetPageContainer } from './ResetPage.styled'
import { ResetPageProps, ResetFormFields } from './ResetPage.types'
import WaitingSpinner from '../../components/ui/WaitingSpinner/WaitingSpinner'

const validationSchema = yup.object({
  password: passwordValidator.label('New Password'),
  confirm: yup
    .string()
    .oneOf([yup.ref('password'), undefined], "Passwords Don't Match")
    .required('Password confirmation is required')
    .nullable(),
})

const initialValues: ResetFormFields = {
  password: null,
  confirm: null,
  error: null,
}

const ResetPage: React.FC<RouteComponentProps<ResetPageProps>> = ({
  match: {
    params: { token },
  },
}) => {
  const { handleReset, loading, isDone, isInvalid } = useReset(token)
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleReset,
  })
  let content: React.ReactNode

  if (isDone) {
    content = (
      <FlexBox alignItems="stretch">
        <h2 className="ion-text-center">Done</h2>
        <p className="ion-text-center">Your password has been reset</p>
        <Button className="ion-margin-top" routerLink="/login">
          Go back to login
        </Button>
      </FlexBox>
    )
  }

  if (isInvalid) {
    content = (
      <FlexBox alignItems="stretch">
        <h2 className="ion-text-center">Invalid Link</h2>
        <p className="ion-text-justify">
          We are sorry, but the link has expired or is incorrect.
        </p>
        <p className="ion-text-justify">
          Please ensure the exact link was copied from the password reset email.
        </p>
        <Button routerLink="/login">Go back to login</Button>
        <FlexBox className="ion-margin-top" alignItems="stretch">
          <Button routerLink="/forgot">Resend Email</Button>
        </FlexBox>
      </FlexBox>
    )
  }

  if (!isDone && !isInvalid) {
    content = (
      <FormikProvider value={formik}>
        <FlexBox alignItems="stretch">
          <h2 className="ion-text-center ion-margin-bottom">
            Reset your password
          </h2>

          <TextInputField
            className="ion-margin-vertical"
            type="password"
            name="password"
            placeholder="New password"
            autocomplete="new-password"
          />
          <TextInputField
            className="ion-margin-bottom"
            type="password"
            name="confirm"
            placeholder="Re-enter the new password"
          />
          <ErrorMessage name="error" component={ErrorComponent} />
          <p>
            Your password must be a minimum of 10 characters, and include at
            least one lowercase letter, one uppercase letter, one symbol and a
            number.
          </p>
          <Button disabled={loading} onClick={formik.submitForm}>
            Reset Password
          </Button>
          <Link className="ion-text-center ion-margin-vertical" to="/login">
            Back
          </Link>
        </FlexBox>
      </FormikProvider>
    )
  }

  return (
    <IonPage data-testid="ResetPage">
      <IonContent className="ion-padding-horizontal">
        <ResetPageContainer>{content}</ResetPageContainer>
        {loading && <WaitingSpinner />}
      </IonContent>
    </IonPage>
  )
}

export default ResetPage
